import template from './template.js'
export default {
    tpl: {
        page: "<div>\n\
                    <div class='content'>\n\
                    </div>\n\
                </div>",
        
        modal: "<div class='modal fade' tabindex='-1' role='dialog' aria-labelledby='myModalLabel'>\n\
                    <div class='modal-dialog modal-lg' role='document'>\n\
                        <div class='modal-content'>\n\
                            <div class='modal-header'>\n\
                                <h4 class='modal-title' id='myModalLabel'>Modal title</h4>\n\
                                <button type='button' class='close btn-close' data-bs-dismiss='modal' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button>\n\
                            </div>\n\
                            <div class='modal-body'>\n\
                            </div>\n\
                            <div class='modal-footer'>\n\
                                <button type='button' class='btn btn-default' data-bs-dismiss='modal' data-dismiss='modal'>Close</button>\n\
                                <span class='buttons'></span>\n\
                            </div>\n\
                        </div>\n\
                    </div>\n\
                </div>",
        
        table_order: "<select name='generic-listing-order' class='form-control input-sm'></select>",
        table_limit: "<select name='generic-listing-limit' class='form-control input-sm'><option value='1'>1</option><option value='5'>5</option><option value='10'>10</option><option value='25'>25</option><option value='50'>50</option><option value='100'>100</option><option value='0'>All</option></select>",
        table_filter: "<input name='generic-listing-filter' class='form-control input-sm'/><button class='btn' name='generic-listing-filter-btn'><i class='fa fa-search'></i></button>",
        table_generic: "<table id='generic-listing' class='table table-rounded table-striped table-bordered'></table>",
        table_action: "<button class='btn btn-sm'></button>",
        
        list_generic: "<div id='generic-listing'></div>",
        list_action: "<button class='btn'></button>",
        
        // append the input and help text to the controls div
        bootstrap: "<div class='form-group'><label class='control-label bmd-label-floating'></label><div class='controls'></div></div>",
        // insert a label and input into this variable
        bootstrap_inline: "<div class='control-inline'><div class='controls'></div></div>",
        bootstrap_help: "<span class='help-inline'></span>",

        bootstrap_accordion: '<div class="accordion" id="accordion{id}">\n\
                                <div class="card">\n\
                                    <div class="card-header" id="heading{id}">\n\
                                        <a class="btn btn-block text-left" data-toggle="collapse" data-target="#collapse{id}" aria-expanded="false" aria-controls="collapse{id}">\n\
                                            {title} <i class="fa fa-angle-down pull-right"></i>\n\
                                        </a>\n\
                                    </div>\n\
                                    <div id="collapse{id}" class="collapse" aria-labelledby="heading{id}" data-parent="#accordion{id}">\n\
                                        <div class="card-body">\n\
                                        </div>\n\
                                    </div>\n\
                                </div>\n\
                            </div>',
        
        alert: "<div class='alert fade in'><div class='container'><button type='button' class='close' data-dismiss='alert'>×</button></div></div>",
        
        input: "<input class='form-control'/>",
        typeahead: "<input class='form-control' class='typeahead' data-provide='typeahead'/>",
        textarea: "<textarea class='form-control'></textarea>",
        ck5div: "<div class='form-control'></div>",
        select: "<select class='form-control' data-live-search='true'></select>",//"<div class='combobox'><select class='form-control'></select></div>",
        multi_select: "<select class='form-control' multiple='multiple' data-live-search='true'></select>",
        select_add: "<div class='select-add'><div class='combobox'><select class='form-control'></select></div><button class='btn select-add-btn'><i class='fa fa-plus'></i></button><input type='hidden'/><ul></ul></div>",
        checkbox_wrap: "<div class='checkbox'><label></label></div>",
        checkbox: "<div class='checkbox'><label><input type='checkbox'></label></div>",
        radio: "<div class='radio'><label><input type='radio'/></label></div>",
        color_display: "<div class='form-control color-display'></div>",
        dropzone: "<div class='generic-dropzone list-group-item allow-files-drop lazy-image'>\n\
                        <div class='row align-items-center justify-content-center w-100 h-100'>\n\
                            <div class='col'>\n\
                                <div class='ajax-uploading hide-forced'><i class='fa fa-spinner fa-spin fa-3x'></i></div>\n\
                                <a href='#' class='action-remove-file hide-forced'><i class='fa fa-remove float-right'></i></a>\n\
                                <span class='drag-n-drop text'>Drag and drop file here or click to browse</span>\n\
                            </div>\n\
                        </div>\n\
                    </div>",
        multi_image: "<div class='generic-dropzone lazy-image'>\n\
                        <a href='#' class='action-remove-file'><i class='fa fa-remove float-right'></i></a>\n\
                    </div>",
        
        label: "<label></label>",
        
        option: "<option></option>",
        
        // if required add a red astirix
        required: "<font color='red'>*</font>",
    },

    fixedNumber: function(n) {
        return n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    },
    
    secondsToString: function(seconds) {
        var numyears = Math.floor(seconds / 31536000);
        var numdays = Math.floor((seconds % 31536000) / 86400); 
        var numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
        var numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
        var numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;
        return (numyears > 0 ? numyears + " years " : "") + (numdays > 0 ? numdays + " days " : "") + numhours + " hour"+(numhours > 0 ? "s" : "")+" and " + (numminutes+"").padStart(2,"0") + ":" + (numseconds+"").padStart(2,"0");
    },
    
    dateFromString: function(dateStr) {
        if(typeof(dateStr) === 'undefined' || dateStr === null) return new Date();
        // unix time date?
        if(!isNaN(dateStr)) {
            return new Date(Number(dateStr)*1000);
        }
        // otherwise date string
        var a=dateStr.split(" ");
        var d=a[0].split("-");
        if(a.length > 1) {
            var t=a[1].split(":");
            return new Date(d[0],(d[1]-1),d[2],t[0],t[1],t[2]);
        } else {
            return new Date(d[0],(d[1]-1),d[2]);
        }
    },
    
    dateFromNumber: function(dateUnixTime) {
        return new Date(dateUnixTime);
    },
    
    dateISOString: function(dateStr) {
        return this.dateFromString(dateStr).toISOString();
    },
    
    dateLocaleString: function(dateStr) {
        return this.dateFromString(dateStr).toLocaleString();
    },
    
    uncamelize: function(str) {
        const regex = /([A-Z])(?=[A-Z][a-z])|([a-z])(?=[A-Z])/g;
        return str.replace(regex, '$& ');
    },
    
    toTitleCase: function(text) {
        var words = text.match(/[A-Za-z][a-z]*/g);
        return words.map(this.capitalize).join(" ");
    },
    
    capitalize: function(word) {
        return word.charAt(0).toUpperCase() + word.substring(1);
    },
    
    invalidFields: function(fields, invalid) {
        
        var message = "<strong>Some fields are invalid:</strong><br><ul>";
        for(var i in invalid) {
            if(typeof(invalid[i].message) !== 'undefined') {
                message+= "<li>"+(typeof(fields[i].Label) !== 'undefined' ? fields[i].Label : this.toTitleCase(i))+": "+invalid[i].message+"</li>";
            }
        }
        message+= "</ul>";
        
        return message;
    },
    
    formField: function(form_name, field, details, value, readonly_all, inline) {
        if(details.Permission==="none") return;

        var readonly = (details.Permission==="view" ? true : (readonly_all || false));
        inline = inline || false;
        
        if(details.Extra.toLowerCase().indexOf("auto_increment")===-1 && !readonly) {

            try {

                var required = (details.Null.toLowerCase().indexOf("no")!==-1 ? true : false);
                var label = (typeof(details.Label) !== 'undefined' ? details.Label : this.toTitleCase(field));

                var $control = $(inline ? this.tpl.bootstrap_inline : this.tpl.bootstrap);
                if(details.Class) {
                    $control.addClass(details.Class);
                }

                if(!inline) {
                    if(details.Label === false || details.Type === 'hidden') {
                        $control.find("label.control-label").remove();
                    } else {
                        $control.find("label.control-label").html(label+(required ? " "+this.tpl.required : ""));
                    }
                }

                var $input = "";

                if(details.Type.toLowerCase().indexOf("varchar")!==-1 || 
                        details.Type.toLowerCase().indexOf("string")!==-1 || 
                        details.Type.toLowerCase().indexOf("textbox")!==-1 ||
                        details.Type.toLowerCase().indexOf("email")!==-1 || 
                        details.Type.toLowerCase().indexOf("tel")===0) {
                    // text
                    $input = $(this.tpl.input);
                    $input.attr("type","text");
                    if(typeof(details.MinLength) !== 'undefined') {
                        $input.attr("minlength",details.MinLength);
                    }
                    if(typeof(details.MaxLength) !== 'undefined') {
                        $input.attr("maxlength",details.MaxLength);
                    }
                    $input.val(value);

                    
                } else if(details.Type.toLowerCase().indexOf("int")===0) {

                    $input = $(this.tpl.input);
                    $input.attr("type","number");
                    if(typeof(details.Max) !== 'undefined') {
                        $input.attr("max",details.Max);
                    }
                    if(typeof(details.Min) !== 'undefined') {
                        $input.attr("min",details.Min);
                    }
                    $input.val(value);
                    
                } else if(details.Type.toLowerCase().indexOf("double")!==-1 || 
                            details.Type.toLowerCase().indexOf("float")!==-1 || 
                            details.Type.toLowerCase().indexOf("decimal")!==-1) {

                    $input = $(this.tpl.input);
                    $input.attr("type","number").attr("step",typeof(details.Step) !== 'undefined' ? details.Step : "any");
                    if(typeof(details.Max) !== 'undefined') {
                        $input.attr("max",details.Max);
                    }
                    if(typeof(details.Min) !== 'undefined') {
                        $input.attr("min",details.Min);
                    }
                    $input.val(value);
                    
                } else if(details.Type.toLowerCase().indexOf("password")!==-1) {
                    // password
                    $input = $(this.tpl.input);
                    $input.attr("type","password");
                    $input.val(value);
                    
                } else if(details.Type.toLowerCase().indexOf("typeahead")!==-1) {
                    // typeahead
                    $input = $(this.tpl.typeahead);
                    $input.attr("autocomplete","off");
                    $input.val(value);
                    
                } else if(details.Type.toLowerCase() === "color") {
                    // color
                    $input = $(this.tpl.input);
                    $input.attr("type","color");
                    $input.val(value);

                } else if(details.Type.toLowerCase().indexOf("hidden")!==-1) {
                    // hidden
                    $input = $(this.tpl.input);
                    $input.attr("type","hidden");
                    $input.val(value);

                } else if(details.Type.toLowerCase().indexOf("color_display")!==-1) {
                    // color display
                    $input = $(this.tpl.color_display);
                    $input.css("background-color",value);

                } else if(details.Type.toLowerCase().indexOf("text")!==-1 || details.Type.toLowerCase().indexOf("textarea")!==-1) {
                    // textarea
                    $input = $(this.tpl.textarea);
                    $input.val(value);
                    
                } else if(details.Type.toLowerCase().indexOf("html")!==-1) {
                    // textarea
                    $input = $(this.tpl.textarea);
                    $input.val(value);

                } else if(details.Type.toLowerCase().indexOf("address")!==-1) {

                    var $hidden = $(this.tpl.input);
                    $hidden.attr("type","hidden");
                    $hidden.val(value);

                    var $address = $(this.tpl.input);
                    $address.attr("type","text");
                    $address.attr("placeholder","Start typing an address");

                    $input = $("<div></div>").append($address).append($hidden);

                } else if(details.Type.toLowerCase().indexOf("select_add")===0) {
                    if(typeof SelectAdd === 'undefined') {
                        throw new Exception('SelectAdd is missing.');
                    }
                    // select list with add button
                    $input = $(this.tpl.select_add);
                    $select = $input.find("select");
                    for(var i in details.Items) {
                        var $option = $(this.tpl.option);
                        $option.val(i);
                        $option.html(details.Items[i][0]); // add label
                        if(value==details.Items[i][0]) {
                            $option.prop("selected",true);
                        }
                        $option.attr("disabled",!details.Items[i][1]);
                        $select.append($option);
                    }

                    for(var i in details.ItemsDefaultStart) {
                        var title = $select.find("option[details="+details.ItemsDefaultStart[i]+"]").html();
                        SelectAdd.AddDefaultStart(details.ItemsDefaultStart[i],title,$input);
                    }

                    //console.log(value)
                    if(typeof(value) === 'object') {
                        for(var i in value) {
                            if(value[i] != "") {
                                var title = $select.find("option[details="+value[i]+"]").html();
                                SelectAdd.Insert(value[i],title,$input);
                            }
                        }
                    }

                    for(var i in details.ItemsDefaultEnd) {
                        var title = $select.find("option[details="+details.ItemsDefaultEnd[i]+"]").html();
                        SelectAdd.AddDefaultEnd(details.ItemsDefaultEnd[i],title,$input);
                    }

                } else if(details.Type.toLowerCase().indexOf("select")===0) {
                    // select
                    $input = $(this.tpl.select);

                    this.updateSelectOption($input, value, details.Items);

                    /*if(typeof(details.Items) !== 'undefined' && details.Items) {
                        //$select = $input.find("select");
                        //console.log(typeof(details.Items));
                        if(!(details.Items instanceof Map)) {
                            details.Items = new Map(Object.entries(details.Items));
                        }
                        
                        for(const [key, item] of details.Items) {

                            //console.log(Array.isArray(details.Items[i]))
                            
                            if(!Array.isArray(item)) {
                                $input.append('<optgroup label="'+key+'"/>');
                                for(var j in item) {
                                    var $option = $(this.tpl.option);
                                    //console.log(j)
                                    $option.val(j);
                                    $option.html(item[j][0]); // add label
                                    if(value==j) {
                                        $option.prop("selected",true);
                                    }
                                    $option.attr("disabled",!item[j][1]);
                                    $input.append($option);
                                }

                            } else {
                                var $option = $(this.tpl.option);
                                $option.val(key);
                                $option.html(item[0]); // add label
                                if(value==key) {
                                    $option.prop("selected",true);
                                }
                                $option.attr("disabled",!item[1]);
                                $input.append($option);
                            }
                        }
                    }*/

                } else if(details.Type.toLowerCase().indexOf("multi_select")!==-1) {
                    // select
                    $input = $(this.tpl.multi_select);
                    for(var i in details.Items) {
                        var $option = $(this.tpl.option);
                        $option.val(i);
                        $option.html(details.Items[i][0]); // add label
                        if(value.indexOf(i)!==-1) {
                            $option.prop("selected",true);
                        }
                        $option.attr("disabled",!details.Items[i][1]);
                        $input.append($option);
                    }
                    
                } else if(details.Type.toLowerCase().indexOf("radio")!==-1) {
                    // radio
                    $input = $("<div class='radio-list'>");
                    for(var i in details.Items) {
                        var $radioGroup = $(this.tpl.radio);
                        $radioGroup.find("input").val(i);
                        $radioGroup.find("label").append(details.Items[i][0]); // add label
                        if(value == i) {
                            $radioGroup.find("input").prop("checked",true);
                        }
                        $radioGroup.find("input").attr("disabled",!details.Items[i][1]);
                        $input.append($radioGroup);
                    }
                    
                } else if(details.Type.toLowerCase().indexOf("tinyint")!==-1 || details.Type.toLowerCase().indexOf("bool")!==-1) {
                    // boolean
                    $input = $("<div class='checkbox-list'></div>");
                    var $checkGroup = $(this.tpl.checkbox);
                    $checkGroup.find("label").append(label); // add label
                    $checkGroup.find("input").val("1");
                    $checkGroup.find("input").attr("checked",(value == 1));
                    $input.append($checkGroup)
                    
                } else if(details.Type.toLowerCase().indexOf("checkbox")!==-1) {
                    // checkbox list
                    $input = $("<div class='checkbox-list'></div>");
                    for(var i in details.Items) {

                        try {
                        
                            var $checkGroup = $(this.tpl.checkbox);
                            $checkGroup.find("label").append(details.Items[i][0]); // add label
                            $checkGroup.find("input").val(i);
                            if(value && value.indexOf(i.toString())!==-1 && i.length > 0) {
                                $checkGroup.find("input").prop("checked",true);
                            }
                            $checkGroup.find("input").attr("disabled",!details.Items[i][1]);
                            $input.append($checkGroup)

                        } catch(e) {
                            console.error("Field "+field+" checkbox item error",i,details.Items[i])
                        }
                        
                    }

                } else if(details.Type.toLowerCase().indexOf("timestamp")!==-1 || details.Type.toLowerCase().indexOf("date")!==-1 || details.Type.toLowerCase().indexOf("time")!==-1) {
                    // datepicker
                    $input = $(this.tpl.input);
                    $input.attr("type","date");
                    //console.log(value,new Date(value).toLocaleString("en-ZA").replace('/','-').replace('/','-').substring(0,10),new Date(value).toISOString());
                    try {
                        if(value) {
                            $input.val(new Date(value).toLocaleString("en-ZA").replace('/','-').replace('/','-').substring(0,10));
                        }
                    } catch(e) {
                        console.error("Field Error ("+field+"):",e);
                    }
                    
                } else if(details.Type.toLowerCase().indexOf("ckeditor")!==-1) {
                    
                    // ckeditor 4
                    if(typeof CKEDITOR !== "undefined") {
                        // textarea as ckeditor
                        $input = $(this.tpl.textarea);
                        $input.val(value);

                        CKEDITOR.replace( $input[0] );
                        //CKEDITOR.config.contentsCss = ['libs/bootstrap-material-design/css/bootstrap-material-design.min.css', 'css/style.css'];
                        CKEDITOR.config.allowedContent = true;
                    }
                    else
                    // ckeditor 5 classic editor
                    if(typeof ClassicEditor !== "undefined") {
                        // textarea as ckeditor
                        $input = $(this.tpl.textarea);

                        ClassicEditor.create( $input[0], {
                            initialData: value
                        } )
                            .then( editor => {
                                $input[0].ckeditor = editor;
                                if(typeof $input[0].onCkeditorReady !=='undefined') {
                                    $input[0].onCkeditorReady(editor);
                                }
                            } )
                            .catch( error => {
                                console.error( "Field Error ("+field+"):",error );
                            } );
                    } else
                    // ckeditor 5 inline editor
                    if(typeof InlineEditor !== "undefined") {
                        // textarea as ckeditor
                        $input = $(this.tpl.ck5div);
                        
                        InlineEditor.create( $input[0], {
                            initialData: value
                        }  )
                            .then( editor => {
                                $input[0].ckeditor = editor;
                                if(typeof $input[0].onCkeditorReady !=='undefined') {
                                    $input[0].onCkeditorReady(editor);
                                }
                            } )
                            .catch( error => {
                                console.error( "Field Error ("+field+"):",error );
                            } );
                    } else {
                        console.error("Field Error ("+field+"): requires CKEditor 4 or 5");
                    }
                    
                } else if(details.Type.toLowerCase().indexOf("image")!==-1) {
                    // image upload
                    $input = $(this.tpl.input);
                    $input.attr("type","hidden");
                    $input.val(value);

                } else if(details.Type.toLowerCase().indexOf("file")!==-1) {
                    // file upload
                    $input = $(this.tpl.input);
                    $input.attr("type","hidden");
                    $input.val(value);

                } else {
                    $input = $("<span class='flat-text'>"+value+"</span>");
                }

                $control.find("div.controls").append($input);

                if(typeof details.Help !== 'undefined') {
                    var $help = $(this.tpl.bootstrap_help);
                    $help.append(details.Help);
                    $control.append($help);
                }

                if(typeof($input)!=='string') {
                    if(required) {
                        $input.attr("required","required");
                    }

                    if(typeof details.Placeholder !== "undefined") {
                        $input.attr("placeholder",details.Placeholder);
                    }

                    if(details.Type.toLowerCase().indexOf("checkbox")!==-1) {

                        $input.find("input:checkbox").attr("name",field+"[]");

                    } else if(details.Type.toLowerCase().indexOf("radio")!==-1) {

                        $input.find("input:radio").attr("name",field+"");

                    } else if(details.Type.toLowerCase().indexOf("tinyint")!==-1 || details.Type.toLowerCase().indexOf("bool")!==-1) {

                        $input.find("input:checkbox").attr("name",field);

                    } else if(details.Type.toLowerCase().indexOf("select")===0 ||
                                details.Type.toLowerCase().indexOf("multi_select")===0 ||
                                details.Type.toLowerCase().indexOf("timestamp")!==-1 ||
                                details.Type.toLowerCase().indexOf("date")!==-1 ||
                                details.Type.toLowerCase().indexOf("time")!==-1) {
                        
                        $control.find(".bmd-label-floating").removeClass("bmd-label-floating").addClass("bmd-label-static");
                        $input.attr("name",field);
                        //$input.find("select").attr("name",field);

                        if((details.Type.toLowerCase().indexOf("select")===0 || details.Type.toLowerCase().indexOf("multi_select")===0)) {
                            // $input has not been added to the dom yet and this sometimes causes selectpicker to break
                            //$input.selectpicker();
                        }

                    } else if(details.Type.toLowerCase().indexOf("ckeditor")===0) {
                        
                        $control.find(".bmd-label-floating").removeClass("bmd-label-floating").addClass("bmd-label-static");
                        //$input.selectpicker();
                        $input.attr("name",field);
                        

                    } else if(details.Type.toLowerCase().indexOf("typeahead")===0) {

                        var source = [];
                        for(var i in details.Items) {
                            source.push(details.Items[i][0]);
                        }
                        
                        $input.attr("name",field);
                        $input.attr("id",field);

                        /*$input.typeahead({
                            source: source,
                            autoSelect: false,
                            showHintOnFocus: 'all',
                            items: 'all'
                        });*/

                        if(typeof Bloodhound !== 'undefined') {
                            // constructs the suggestion engine
                            var suggestions = new Bloodhound({
                                datumTokenizer: Bloodhound.tokenizers.whitespace,
                                queryTokenizer: Bloodhound.tokenizers.whitespace,
                                // `states` is an array of state names defined in "The Basics"
                                local: source
                            });

                            function suggestionsWithDefaults(q, sync) {
                                if (q === '') {
                                    sync(source);
                                }
                                else {
                                    suggestions.search(q, sync);
                                }
                            }
                            
                            $input.typeahead({
                                hint: true,
                                highlight: true,
                                minLength: 0
                            },
                            {
                                name: 'suggestions',
                                source: suggestionsWithDefaults
                            });
                        } else {
                            console.error("Field "+field+" requires Bloodhound and Typeahead plugins.");
                        }

                    } else if(details.Type.toLowerCase().indexOf("address")!==-1) {

                        $input.find("input[type=hidden]").attr("name",field);
                        $input.find("input[type=hidden]").attr("id",field);
                        $input.find("input[type=text]").attr("name",field+"_autocomplete");
                        $input.find("input[type=text]").attr("id",field+"_autocomplete");
                        $input.on("focus",Address.geolocate);
                        $input.find("input[type=hidden]").after("<style>.pac-container{z-index:1100;}</style>");

                    } else if(details.Type.toLowerCase().indexOf("image") === 0 || details.Type.toLowerCase().indexOf("file") === 0) {

                        var $dropzone = $(this.tpl.dropzone);
                        $dropzone.attr("data-target","[name="+field+"]");
                        $dropzone.attr("data-style","file-selector-preview");
                        $dropzone.attr("data-image-style","file-selector-preview");
                        $dropzone.attr("data-image-type","background");
                        $dropzone.attr("data-image-id",value);

                        $input.parent(".controls").append($dropzone);

                        $input.attr("name",field);
                        $input.attr("id",field);

                    } else if (details.Type.toLowerCase().indexOf("multi_image") === 0 || details.Type.toLowerCase().indexOf("multi_file") === 0) {

                        var fileIds = (value ? value.split(",") : []);
                        
                        var $listGroup = $("<div>").addClass("list-group list-group-horizontal");
                        $input.parent(".controls").append($listGroup);
                        
                        var $dropzone = $(this.tpl.dropzone);
                        $dropzone.attr("data-target","[name="+field+"]");
                        $dropzone.attr("data-style","file-selector-preview");
                        $dropzone.attr("data-image-style","file-selector-preview");
                        $dropzone.attr("data-image-type","background");
                        $dropzone.attr("data-image-id",fileIds.length > 0 ? fileIds[0] : "");
                        $listGroup.append($dropzone);

                        if(fileIds.length > 1) {
                            for(var x = 1; x < fileIds.length; x++) {
                                var $dropzone = $(this.tpl.dropzone);
                                $dropzone.attr("data-target","[name="+field+"]");
                                $dropzone.attr("data-style","file-selector-preview");
                                $dropzone.attr("data-image-style","file-selector-preview");
                                $dropzone.attr("data-image-type","background");
                                $dropzone.attr("data-image-id",fileIds[x]);
                                $listGroup.append($dropzone);
                            }
                        }

                        $input.parent(".controls").find(".action-remove-file").on("click",function(e){
                            var $image = $(this).closest(".generic-dropzone");
                            var ids = $($image.attr("data-target")).val().split(",");
                            if(ids.length > 1) {
                                ids.splice(ids.indexOf($image.attr("data-image-id")),1);
                                $($image.attr("data-target")).val(ids.join(","));
                                $image.remove();
                            } else {
                                $($image.attr("data-target")).val("");
                                $image.css("background-image","none");
                                $image.parent(".controls").find(".action-remove-file").addClass("hide-forced");
                            }
                            $($image.attr("data-target")).trigger("change");
                        });
                        
                        if(typeof $.sortable !== 'undefined') {
                            $listGroup.sortable({
                                onChange: function(e) {
                                    
                                    var newIds = [];
                                    $(e.target).closest(".list-group").find(".list-group-item").each(function(){
                                        newIds.push($(this).data("image-id"));
                                    });
                                    $($(e.item).data("target")).val(newIds.join(","));
                                    $($(e.item).data("target")).trigger("change");
                                }
                            });
                        } else {
                            console.warn("Field "+field+", sortable plugin not found.")
                        }

                        $input.attr("name",field);
                        $input.attr("id",field);

                    } else if(details.Type.toLowerCase().indexOf("tinyint") === -1 || details.Type.toLowerCase().indexOf("bool") === -1) {

                        $input.attr("name",field);
                        $input.attr("id",field);
                        if(details.Placeholder) {
                            $input.attr("placeholder",details.Placeholder);
                        }

                    }
                }

            } catch (e) {
                console.error("Field "+field+" error",e)
            }

        } else {

            //$input = $("<span class='flat-text'>"+value+"</span>");
            //$control.addClass("is-filled").find("div.controls").append($input);
        }
        
        return $control;
    },
    
    formFields: function(form_name, fields, form_state, readonly_all, inline) {
        //Debug.log(form_state);
        var $container = $("<div></div>");
        for(var field in fields) {
            $container.append(this.formField(form_name, field, fields[field], form_state[field], readonly_all, inline));
        }
        return $container.contents();
    },
    
    fieldMultiselect: function(form_name,field,value,form_state,readonly_all) {
        if(value.Permission==="none") {return;}
        
        var readonly = (value.Permission==="view" ? true : (readonly_all || false));

        if(value.Extra.toLowerCase().indexOf("auto_increment")===-1 && !readonly) {

            var required = (value.Null.toLowerCase().indexOf("no")!==-1 ? true : false);
            var label = this.toTitleCase((typeof(value.Label) !== 'undefined' ? value.Label : field));

            var $control = $(this.tpl.bootstrap);
            if(label.trim() === "") {
                $control.find("label.control-label").remove();
            } else {
                $control.find("label.control-label").html(label+(required ? " "+this.tpl.required : ""));
            }
            var $input = "";

            // multi select
            $input = $(this.tpl.multi_select);
            for(var i in value.Items) {
                var $option = $(this.tpl.option);
                $option.val(value.Items[i][0]);
                $option.html(i);
                if(form_state[field]==value.Items[i][0]) {
                    $option.prop("selected",true);
                }
                $input.append($option);
            }

            if(typeof($input)!=='string') {
                $input.attr("name",form_name+"_"+field);
                $input.attr("id",form_name+"_"+field);
            }

            $control.find("div.controls").append($input);
            return $control;
        }
        return null;
    },
    
    /**
     * Render bootstrap classed nav-links.
     * @param {*} links 
     * @param {*} level 
     * @param {*} flat 
     */
    menuNavLinks: function(links,level,flat) {
        if(typeof(level)==="undefined") level = -1;
        level++;
        var output = "";
        for(var i = 0; i < links.length; i++) {
            var link = links[i];
            
            if(link == null) {continue;}
            var has_sub = (link.sub_menu !== null && link.sub_menu.length);
            var classes = "nav-link "+(typeof(link.classes) !== 'undefined' ? link.classes : "")+(has_sub && flat === true ? " disabled" : "");
            link.url = link.url||"";
            
            output+= "<li class='nav-item "+(has_sub && link.url !== "" ? "nav-group " : "")+(has_sub && link.url === "" ? "dropdown" : "")+"' >";
            if((has_sub && link.url === "") || !has_sub) {
                output+= "<a "+(has_sub ? "class='dropdown-toggle "+classes+"' data-toggle='dropdown'" : "class='"+classes+"'")+" data-menu='"+this.slugify(link.title)+"-menu' "+(link.url==="" ? "href='#'" : "href='"+(link.url[0] === "/" ? link.url : ""+link.url)+"'")+">"+link.title+" "+(has_sub && flat !== true && link.url === "" ? "<i class='fa fa-"+(level===0 ? "caret-down" : "caret-right")+"'></i>" : "")+"</a>";
            }
            if(has_sub && link.url !== "") {
                output+= "<a class='"+classes+"' href='"+link.url+"'>"+link.title+"</a>";
                output+= " <a class='dropdown-toggle' data-toggle='dropdown' aria-expanded='false'>\n\
                                <span class='caret'></span>\n\
                                <span class='sr-only'>Toggle Dropdown</span>\n\
                            </a>";
            }
            if(has_sub) {
                output+= "<ul id='"+this.slugify(link.title)+"-menu' class='dropdown-menu "+ (flat !== true ? "" : "show")+"' role='menu'>";
                output+= this.menuNavLinks(link.sub_menu,level,flat);
                output+= "</ul>";
            }
            output+= "</li>";
        }
        return output;
    },

    /**
     * Render bootstrap classed nav-links. Alias of menuLinks.
     * @param {*} links 
     * @param {*} level 
     * @param {*} flat 
     */
    menuLinks: function(links,level,flat) {
        return this.menuNavLinks(links,level,flat);
    },

    /**
     * Render bootstrap Accordion menu.
     * @param {*} links 
     * @param {*} level 
     * @param {*} flat 
     */
    menuAccordion: function(links,level,flat) {
        if(typeof(level)==="undefined") level = -1;
        level++;
        
        var $links = $('<div>');
        for(var i = 0; i < links.length; i++) {
            var link = links[i];
            
            if(link == null) {continue;}
            var has_sub = (link.sub_menu !== null && link.sub_menu.length);
            
            let classes = "nav-link "+(typeof(link.classes) !== 'undefined' ? link.classes : "")+(has_sub && flat === true ? " disabled" : "");
            link.id = this.slugify(link.title)+"-menu";
            
            var $link = $(template.dataValues(this.tpl.bootstrap_accordion, link));
            if(link.url) {
                $link.find('.card .card-header > a').attr('href',link.url);
            }

            if(has_sub) {
                $link.find('.card').addClass('has-sub');
                $link.find('.card-body').append(this.menuAccordion(link.sub_menu,level,flat));
            } else {
                $link.find('.card .card-header > a').attr('data-toggle',null).attr('data-target',null).find('i.fa-angle-down').remove();
            }

            $links.append($link);
        }

        return $links;
    },
    
    slugify: function(text) {
        return text
            .toLowerCase()
            .replace(/[^\w ]+/g,'')
            .replace(/ +/g,'-')
            ;
    },
    
    list: function(headerField, columns,rows,row_count,actions,selected_limit,selected_page,total_rows,filter,total_filtered_rows) {
        var $table_group = $("<div id='generic-listing-wrapper'></div>");
        
        // build table top row inputs
        var $table_top = $("<div class='row'></div>");
        var $limit_select = $("<div class='col-12 col-md-auto'><label class='generic-listing-input'>Show "+this.tpl.table_limit+" entries</label></div>");
        $table_top.append($limit_select);
        $limit_select.find("select").val(selected_limit);
        var $filter_input = $("<div class='col-12 col-md-auto ml-auto'><label class='generic-listing-input'>Search: <div class='d-inline-block'>"+this.tpl.table_filter+"</div></label></div>");
        $table_top.append($filter_input);
        $filter_input.find("input").val(filter);
        
        // build table bottom row
        var $table_bottom = $("<div class='row'></div>");
        var pages = Math.ceil(total_rows/(selected_limit > 0 ? selected_limit : total_rows));
        //console.log(pages)
        var page_start_from = (selected_page*(selected_limit > 0 ? selected_limit : 1));
        var $num_rows = $("<div class='col-12 col-md-auto'>Showing "+(page_start_from+1)+" to "+(page_start_from+row_count)+" of "+(filter !== "" ? total_filtered_rows : total_rows)+" entries"+ (filter !== "" ? " ("+total_rows+" total entries)" : "")+"</div>")
        $table_bottom.append($num_rows);
        $table_bottom.append("<div class='col-12 col-md-auto ml-auto'>"+this.pager(selected_page,selected_limit,(filter !== "" ? total_filtered_rows : total_rows))+"</div>");
        
        // build table and contents
        var $table = $(this.tpl.list_generic);
        var $thead = $("<div></div>");
        var $tbody = $("<div></div>").addClass("tbody");
        $table.append($thead).append($tbody);
        /*var $row = $("<tr></tr>");
        var count = 0;*/
        var max = Object.keys(columns).length;
        /*for(var field in columns) {
            count++;
            if((count < 9 || count === max) && columns[field].Type!=="ckeditor") {
                $row.append("<th>"+(columns[field].Label ? columns[field].Label : this.toTitleCase(field))+"</th>");
            }
        }
        if(actions.length) {
            $row.append("<th>Actions</th>");
        }
        $thead.append($row);*/

        var sortColumn = 0;
        var keys = Object.keys(rows).sort(function(a,b){return a-b;});
        var count = 0;
        //console.log(rows)
        //console.log(keys)
        for(var i in keys) {
            i = keys[i];
            
            var $card = $("<div class='tr card margin-bottom-sml'></div>");
            var $cardHeader = $("<div class='card-header'><h5>"+rows[i][headerField]+"</h5></div>");
            var $cardBody = $("<div class='card-body'></div>");
            var $row = $("<div class='row'></div>");
            
            count = 0;

            if(typeof rows[i].id !== 'undefined') {
                $card.attr("data-id", (typeof rows[i].id !== 'undefined' ? rows[i].id : (typeof rows[i].ID !== 'undefined' ? rows[i].ID : "id_field_unknown")));
            }

            for(var field in columns) {
                if(field=="createDate") {
                    sortColumn = count;
                }
                count++;
                if((count < 12 || count == max) && field !== headerField) {
                    var $col = $("<div class='col-12 col-sm-auto'></div>");
                    
                    // add a label
                    $col.append("<b class='d-block d-sm-inline'>"+(columns[field].Label ? columns[field].Label : this.toTitleCase(field))+":</b> ");
                    
                    if(columns[field].Type.toLowerCase().indexOf("image") > -1) {
                        if(rows[i][field] && rows[i][field].length > 0) {
                            if(columns[field].Type.toLowerCase().indexOf("image") === 0) { // indicates single image
                                $col.append("<img src='"+App.url+"file/"+rows[i][field]+"?style=admin-list'/>");
                            } else { // type 'multi_image'
                                var images = rows[i][field].split(",");
                                for(var x = 0; x < images.length; x++) {
                                    $col.append("<img src='"+App.url+"file/"+images[x]+"?style=admin-list'/>");
                                }
                            }
                        } else {
                            $col.append("");
                        }
                    } else if(columns[field].Type==="ckeditor") {
                        var val = "";
                        try {
                            val = atob(rows[i][field]);
                        } catch(e) {
                            //console.log(e)
                            val = rows[i][field];
                        }
                        val = rows[i][field].length > 1024 ? val.substr(0,1024)+"..." : val;
                        $col.append(val);
                    } else if(columns[field].Type.toLowerCase().indexOf("tinyint")!==-1 || columns[field].Type==="boolean") {
                        $col.append(rows[i][field]==1 ? "Yes" : "No");
                    } else if((columns[field].Type.indexOf("select")!==-1 || columns[field].Type==="multi_select") && columns[field].ItemsLabeled) {
                        $col.append(columns[field].ItemsLabeled[rows[i][field]]);
                    } else if(columns[field].Type.indexOf("color_display")!==-1) {
                        $col.append("<span class='colour-display' style='background-color:"+rows[i][field]+";'></span>");
                    } else {
                        if(rows[i][field]) {
                            var val = rows[i][field].length > 1024 ? rows[i][field].substr(0,1024)+"..." : rows[i][field];
                            if(columns[field].LeftPad) {
                                val = (val+"").leftPad(columns[field].LeftPad[0],columns[field].LeftPad[1]);
                            }
                            if(columns[field].Prefix) {
                                val = columns[field].Prefix + val;
                            }
                            $col.append(val);
                        } else {
                            $col.append("");
                        }
                    }
                    $row.append($col);
                }
            }
            
            $card.append($cardHeader).append($cardBody.append($row));
            
            if(actions.length) {
                var $actions = $("<div class='card-footer text-right'></div>");
                for(var j in actions) {
                    var action = $(this.tpl.list_action).attr("data-action",actions[j].Action).attr("data-item-id",rows[i][actions[j].IdField]).addClass(actions[j].Class).html(actions[j].Label);
                    $actions.append(action);
                }
                $card.append($actions);
                //$row.append($actions);
            }
            
            
            $tbody.append($card);
        }
        
        $table_group.append($table_top).append($table).append($table_bottom);
        
        return {table:$table_group,sortColumn:sortColumn};
    },
    
    listTemplate: function(columns, rows, row_count, actions, selected_limit, selected_page, total_rows, filter, total_filtered_rows, order, order_options, itemTemplate, topTemplate, bottomTemplate) {
        var $table_group = $("<div id='generic-listing-wrapper'></div>");
        
        // build table top row inputs
        var $table_top = $(topTemplate);
        $table_top.addClass("generic-listing-top");
        
        // build table bottom row
        var $table_bottom = $(bottomTemplate);
        $table_bottom.addClass("generic-listing-bottom");
        
        var $order_options = "";
        var $num_rows = "";
        var $limit_select = "";
        var $filter_input = "";
        var $pager = "";
        filter = filter == null ? '' : filter;
        
        var pages = Math.ceil(total_rows/(selected_limit > 0 ? selected_limit : total_rows));
        var page_start_from = (selected_page*(selected_limit > 0 ? selected_limit : 1));
        
        $num_rows = ""+(((filter !== false && total_rows) || (filter === false && row_count > 0)) ? "<label class='generic-listing-input'>Showing "+(row_count === 0 ? 0 : (page_start_from+1))+" to "+(page_start_from+row_count)+" of " +(filter !== false && filter.length !== 0 ? total_filtered_rows : total_rows)+((filter === false && row_count > 0) ? " results" : " total entries")+"</label>" : "Your search has returned no results")+"";
        
        if((filter !== false && total_rows) || (filter === false && row_count > 0)) {
            $limit_select = "<label class='generic-listing-input'>Show&nbsp;"+this.tpl.table_limit+"&nbsp;entries</label>";
            $pager = "<label class='generic-listing-input'>"+this.pager(selected_page,selected_limit,(filter.length !== 0 ? total_filtered_rows : total_rows))+"</label>";
            
            if(order_options) {
                $order_options = $("<label class='generic-listing-input'>Order:&nbsp;"+this.tpl.table_order+"</label>");
                for(var i in order_options) {
                    $order_options.find("select[name='generic-listing-order']").append("<option value='"+order_options[i].id+"'>"+order_options[i].title+"</option>")
                }
            }
            
            if(filter !== false) {
                $filter_input = "<label class='generic-listing-input'>Search:&nbsp;<div class='d-inline-block'>"+this.tpl.table_filter+"</div></label>";
            }
        }
        
        $table_top.find(".generic-listing-order-wrapper").append($order_options);
        $table_top.find(".generic-listing-description-wrapper").append($num_rows);
        $table_top.find(".generic-listing-limit-wrapper").append($limit_select);
        $table_top.find(".generic-listing-filter-wrapper").append($filter_input);
        $table_top.find(".generic-listing-pager-wrapper").append($pager);
        $table_top.find("select[name='generic-listing-limit'] option[value='"+selected_limit+"']").attr('selected','selected');
        $table_top.find("[name='generic-listing-filter']").attr('value',filter);
        $table_top.find("select[name='generic-listing-order'] option[value='"+order+"']").attr('selected','selected');
        
        if(row_count > 0) {
            $table_bottom.find(".generic-listing-order-wrapper").append($order_options == "" ? $order_options : $order_options.clone());
            $table_bottom.find(".generic-listing-description-wrapper").append($num_rows);
            $table_bottom.find(".generic-listing-limit-wrapper").append($limit_select);
            $table_bottom.find(".generic-listing-filter-wrapper").append($filter_input);
            $table_bottom.find(".generic-listing-pager-wrapper").append($pager);
            $table_bottom.find("select[name='generic-listing-limit'] option[value='"+selected_limit+"']").attr('selected','selected');
            $table_bottom.find("[name='generic-listing-filter']").attr('value',filter);
            $table_bottom.find("select[name='generic-listing-order'] option[value='"+order+"']").attr('selected','selected');
        }
        
        // build table and contents
        var $table = $(this.tpl.list_generic);
        //var $thead = $("<div></div>");
        var $tbody = ($("<div></div>").addClass("tbody"));
        //$table.append($thead).append($tbody);
        $table.append($tbody);
        /*var $row = $("<tr></tr>");
        var count = 0;*/
        var max = Object.keys(columns).length;
        /*for(var field in columns) {
            count++;
            if((count < 9 || count === max) && columns[field].Type!=="ckeditor") {
                $row.append("<th>"+(columns[field].Label ? columns[field].Label : this.toTitleCase(field))+"</th>");
            }
        }
        if(actions.length) {
            $row.append("<th>Actions</th>");
        }
        $thead.append($row);*/

        var sortColumn = 0;
        var keys = Object.keys(rows).sort(function(a,b){return a-b;});
        //console.log(rows)
        //console.log(keys)
        for(var i in keys) {
            i = keys[i];
            
            var $card = $(qsutils.template.dataValues(itemTemplate,rows[i])).addClass("tr");
            
            if(typeof rows[i].id !== 'undefined') {
                $card.attr("data-id", (typeof rows[i].id !== 'undefined' ? rows[i].id : (typeof rows[i].ID !== 'undefined' ? rows[i].ID : "id_field_unknown")));
            }

            $tbody.append($card);
        }
        
        if(filter !== false && row_count === 0) {
            $table = "<div class='text-center'>No entries found</div>";
        }
        
        $table_group.append($table_top).append($table).append($table_bottom);
        
        return {table:$table_group,sortColumn:sortColumn};
    },
    
    tableTemplate: function(columns, rows, row_count, actions, selected_limit, selected_page, total_rows, filter, total_filtered_rows, order, order_options, topTemplate, bottomTemplate) {
        var $table_group = $("<div id='generic-listing-wrapper'></div>");
        
        // build table top row inputs
        var $table_top = $(topTemplate);
        $table_top.addClass("generic-listing-top");
        
        // build table bottom row
        var $table_bottom = $(bottomTemplate);
        $table_bottom.addClass("generic-listing-bottom");
        
        var $order_options = "";
        var $num_rows = "";
        var $limit_select = "";
        var $filter_input = "";
        var $pager = "";
        
        var pages = Math.ceil(total_rows/(selected_limit > 0 ? selected_limit : total_rows));
        var page_start_from = (selected_page*(selected_limit > 0 ? selected_limit : 1));
        
        $num_rows = ""+(((filter !== false && total_rows) || (filter === false && row_count > 0)) ? "<label class='generic-listing-input'>Showing "+(row_count === 0 ? 0 : (page_start_from+1))+" to "+(page_start_from+row_count)+" of " +(filter !== false && filter.length !== 0 ? total_filtered_rows : total_rows)+((filter === false && row_count > 0) ? " results" : " total entries")+"</label>" : "Your search has returned no results")+"";
        
        if((filter !== false && total_rows) || (filter === false && row_count > 0)) {
            $order_options = $("<label class='generic-listing-input'>"+this.tpl.table_order+"</label>");
            $limit_select = "<label class='generic-listing-input'>Display&nbsp;"+this.tpl.table_limit+"&nbsp;per page</label>";
            $pager = "<label class='generic-listing-input'>"+this.pager(selected_page,selected_limit,(filter.length !== 0 ? total_filtered_rows : total_rows))+"</label>";
            
            for(var i in order_options) {
                $order_options.find("select[name='generic-listing-order']").append("<option value='"+order_options[i].id+"'>"+order_options[i].title+"</option>")
            }
            
            if(filter !== false) {
                $filter_input = "<label class='generic-listing-input'>"+this.tpl.table_filter+"</label>";
            }
        }
        
        $table_top.find(".generic-listing-order-wrapper").append($order_options);
        $table_top.find(".generic-listing-description-wrapper").append($num_rows);
        $table_top.find(".generic-listing-limit-wrapper").append($limit_select);
        $table_top.find(".generic-listing-filter-wrapper").append($filter_input);
        $table_top.find(".generic-listing-pager-wrapper").append($pager);
        $table_top.find("select[name='generic-listing-limit'] option[value='"+selected_limit+"']").attr('selected','selected');
        $table_top.find("[name='generic-listing-filter']").val(filter);
        $table_top.find("select[name='generic-listing-order'] option[value='"+order+"']").attr('selected','selected');
        
        if(row_count > 0) {
            $table_bottom.find(".generic-listing-order-wrapper").append($order_options.clone());
            $table_bottom.find(".generic-listing-description-wrapper").append($num_rows);
            $table_bottom.find(".generic-listing-limit-wrapper").append($limit_select);
            $table_bottom.find(".generic-listing-filter-wrapper").append($filter_input);
            $table_bottom.find(".generic-listing-pager-wrapper").append($pager);
            $table_bottom.find("select[name='generic-listing-limit'] option[value='"+selected_limit+"']").attr('selected','selected');
            $table_bottom.find("[name='generic-listing-filter']").val(filter);
            $table_bottom.find("select[name='generic-listing-order'] option[value='"+order+"']").attr('selected','selected');
        }
        
        // build table and contents
        var $table = $(this.tpl.table_generic);
        var $thead = $("<thead>");
        var $tbody = $("<tbody>").addClass("tbody");
        $table.append($thead).append($tbody);
        
        // create thead row
        var $row = $("<tr></tr>");
        var count = 0;
        var max = Object.keys(columns).length;
        for(var field in columns) {
            count++;
            if((count < 9 || count === max) && columns[field].Type!=="ckeditor") {
                $row.append("<th>"+(columns[field].Label ? columns[field].Label : this.toTitleCase(field))+"</th>");
            }
        }
        if(actions.length) {
            $row.append("<th>Actions</th>");
        }
        $thead.append($row);
        
        // add tbody content rows
        var sortColumn = 0;
        var keys = Object.keys(rows).sort(function(a,b){return a-b;});
        //console.log(rows)
        //console.log(keys)
        for(var i in keys) {
            i = keys[i];
            var $row = $("<tr></tr>").addClass("tr");
            count = 0;

            if(typeof rows[i].id !== 'undefined') {
                $row.attr("data-id", (typeof rows[i].id !== 'undefined' ? rows[i].id : (typeof rows[i].ID !== 'undefined' ? rows[i].ID : "id_field_unknown")));
            }

            for(var field in columns) {
                if(field=="createDate") {
                    sortColumn = count;
                }
                count++;
                if((count < 9 || count == max)) {
                    var $col = $("<td></td>");
                    if(columns[field].Type.toLowerCase().indexOf("image") !== -1) {
                        if(rows[i][field] && rows[i][field].length > 0) {
                            if(columns[field].Type.toLowerCase().indexOf("image") === 0) { // indicates single image
                                $col.append("<img src='"+App.url+"file/"+rows[i][field]+"?style=admin-table'/>");
                            } else { // type 'multi_image'
                                var images = rows[i][field].split(",");
                                for(var x = 0; x < images.length; x++) {
                                    $col.append("<img src='"+App.url+"file/"+images[x]+"?style=admin-table'/>");
                                }
                            }
                        } else {
                            $col.append("");
                        }
                    } else if(columns[field].Type==="ckeditor") {
                        var val = "";
                        try {
                            val = atob(rows[i][field]);
                        } catch(e) {
                            //console.log(e)
                            val = rows[i][field];
                        }
                        val = rows[i][field].length > 1024 ? val.substr(0,1024)+"..." : val;
                        $col.append(val);
                    } else if(columns[field].Type.toLowerCase().indexOf("tinyint")!==-1 || columns[field].Type==="boolean") {
                        $col.append(rows[i][field]==1 ? "<i class='fa fa-check'></i>" : "");
                    } else if((columns[field].Type.indexOf("select")!==-1 || columns[field].Type==="multi_select") && columns[field].ItemsLabeled) {
                        $col.append(columns[field].ItemsLabeled[rows[i][field]]);
                    } else if(columns[field].Type.indexOf("color_display")!==-1) {
                        $col.append("<span class='colour-display' style='background-color:"+rows[i][field]+";'></span>");
                    } else if(columns[field].Type.indexOf("JobNumber")!==-1) {
                        $col.append(this.jobNumber(rows[i][field]));
                    } else {
                        if(rows[i][field]) {
                            var val = rows[i][field].length > 50 ? rows[i][field].substr(0,50)+"..." : rows[i][field];
                            if(columns[field].LeftPad) {
                                val = (val+"").leftPad(columns[field].LeftPad[0],columns[field].LeftPad[1]);
                            }
                            if(columns[field].Prefix) {
                                val = columns[field].Prefix + val;
                            }
                            $col.append(val);
                        } else {
                            $col.append("");
                        }
                    }
                    $row.append($col);
                }
            }
            
            if(actions.length) {
                var $actions = $("<td></td>");
                for(var j in actions) {
                    var action = $(this.tpl.table_action).attr("data-action",actions[j].Action).attr("data-item-id",rows[i][actions[j].IdField]).addClass(actions[j].Class).html(actions[j].Label);
                    $actions.append(action);
                }
                $row.append($actions);
            }
            
            $tbody.append($row);
        }
        
        if(filter !== false && row_count === 0) {
            $table = "<th colspan='"+count+"' class='text-center'>No entries found</th>";
        }
        
        $table_group.append($table_top).append($table).append($table_bottom);
        
        return {table:$table_group,sortColumn:sortColumn};
    },
    
    table: function(columns,rows,row_count,actions,selected_limit,selected_page,total_rows,filter,total_filtered_rows) {
        var $table_group = $("<div id='generic-listing-wrapper'></div>");
        
        // build table top row inputs
        var $table_top = $("<div class='row'></div>");
        var $limit_select = $("<div class='col-auto'><label class='generic-listing-input'>Show "+this.tpl.table_limit+" entries</label></div>");
        $table_top.append($limit_select);
        $limit_select.find("select").val(selected_limit);
        var $filter_input = $("<div class='col-auto ml-auto'><label class='generic-listing-input'>Search: "+this.tpl.table_filter+"</label></div>");
        $table_top.append($filter_input);
        $filter_input.find("input").val(filter);
        
        // build table bottom row
        var $table_bottom = $("<div class='row'></div>");
        var pages = Math.ceil(total_rows/(selected_limit > 0 ? selected_limit : total_rows));
        //console.log(pages)
        var page_start_from = (selected_page*(selected_limit > 0 ? selected_limit : 1));
        var $num_rows = $("<div class='col-auto'>Showing "+(page_start_from+1)+" to "+(page_start_from+row_count)+" of "+(filter !== "" ? total_filtered_rows : total_rows)+" entries"+ (filter !== "" ? " ("+total_rows+" total entries)" : "")+"</div>")
        $table_bottom.append($num_rows);
        $table_bottom.append("<div class='col-auto ml-auto'>"+this.pager(selected_page,selected_limit,(filter !== "" ? total_filtered_rows : total_rows))+"</div>");
        
        // build table and contents
        var $table = $(this.tpl.table_generic);
        var $thead = $("<thead></thead>");
        var $tbody = $("<tbody></tbody>").addClass("tbody");
        $table.append($thead).append($tbody);
        var $row = $("<tr></tr>");
        var count = 0;
        var max = Object.keys(columns).length;
        for(var field in columns) {
            count++;
            if((count < 9 || count === max)) {
                $row.append("<th>"+(columns[field].Label ? columns[field].Label : this.toTitleCase(field))+"</th>");
            }
        }
        
        if(actions.length) {
            $row.append("<th>Actions</th>");
        }
        $thead.append($row);

        var sortColumn = 0;
        var keys = Object.keys(rows).sort(function(a,b){return a-b;});
        //console.log(rows)
        //console.log(keys)
        for(var i in keys) {
            i = keys[i];
            var $row = $("<tr></tr>").addClass("tr");
            count = 0;

            if(typeof rows[i].id !== 'undefined') {
                $row.attr("data-id", (typeof rows[i].id !== 'undefined' ? rows[i].id : (typeof rows[i].ID !== 'undefined' ? rows[i].ID : "id_field_unknown")));
            }

            for(var field in columns) {
                if(field=="createDate") {
                    sortColumn = count;
                }
                count++;
                if((count < 9 || count == max)) {
                    var $col = $("<td></td>");
                    if(columns[field].Type.toLowerCase().indexOf("image") !== -1) {
                        if(rows[i][field] && rows[i][field].length > 0) {
                            if(columns[field].Type.toLowerCase().indexOf("image") === 0) { // indicates single image
                                $col.append("<img src='"+App.url+"file/"+rows[i][field]+"?style=admin-table'/>");
                            } else { // type 'multi_image'
                                var images = rows[i][field].split(",");
                                for(var x = 0; x < images.length; x++) {
                                    $col.append("<img src='"+App.url+"file/"+images[x]+"?style=admin-table'/>");
                                }
                            }
                        } else {
                            $col.append("");
                        }
                    } else if(columns[field].Type==="ckeditor") {
                        var val = "";
                        try {
                            val = atob(rows[i][field]);
                        } catch(e) {
                            //console.log(e)
                            val = rows[i][field];
                        }
                        $col.append(val.length > 1024 ? val.substr(0,1024)+"..." : val).attr("title", val);
                    } else if(columns[field].Type.toLowerCase().indexOf("tinyint")!==-1 || columns[field].Type==="boolean") {
                        $col.append(rows[i][field]==1 ? "<i class='fa fa-check'></i>" : "");
                    } else if((columns[field].Type.indexOf("select")!==-1 || columns[field].Type==="multi_select") && columns[field].ItemsLabeled) {
                        $col.append(columns[field].ItemsLabeled[rows[i][field]]);
                    } else if(columns[field].Type.indexOf("color_display")!==-1) {
                        $col.append("<span class='colour-display' style='background-color:"+rows[i][field]+";'></span>");
                    } else if(columns[field].Type.indexOf("JobNumber")!==-1) {
                        $col.append(this.jobNumber(rows[i][field]));
                    } else {
                        if(rows[i][field]) {
                            var val = rows[i][field];
                            if(columns[field].LeftPad) {
                                val = (val+"").leftPad(columns[field].LeftPad[0],columns[field].LeftPad[1]);
                            }
                            if(columns[field].Prefix) {
                                val = columns[field].Prefix + val;
                            }
                            $col.append(val.length > 1024 ? val.substr(0,1024)+"..." : val).attr("title", val);
                        } else {
                            $col.append("");
                        }
                    }
                    $row.append($col);
                }
            }
            
            if(actions.length) {
                var $actions = $("<td></td>");
                for(var j in actions) {
                    var action = $(this.tpl.table_action).attr("data-action",actions[j].Action).attr("data-item-id",rows[i][actions[j].IdField]).addClass(actions[j].Class).html(actions[j].Label);
                    $actions.append(action);
                }
                $row.append($actions);
            }
            
            $tbody.append($row);
        }
        
        $table_group.append($table_top).append($table).append($table_bottom);
        
        return {table:$table_group,sortColumn:sortColumn};
    },
    
    updateSelectOption: function($input, value, items) {
         // select
        $input.empty();

        if(typeof(items) !== 'undefined' && items) {
            //$select = $input.find("select");
            //console.log(typeof(items));
            if(!(items instanceof Map)) {
                items = new Map(Object.entries(items));
            }
            
            for(const [key, item] of items) {

                //console.log(Array.isArray(items[i]))
                
                if(!Array.isArray(item)) {
                    $input.append('<optgroup label="'+key+'"/>');
                    for(var j in item) {
                        var $option = $(this.tpl.option);
                        //console.log(j)
                        $option.val(j);
                        $option.html(item[j][0]); // add label
                        if(value==j) {
                            $option.prop("selected",true);
                        }
                        $option.attr("disabled",!item[j][1]);
                        $input.append($option);
                    }

                } else {
                    var $option = $(this.tpl.option);
                    $option.val(key);
                    $option.html(item[0]); // add label
                    if(value==key) {
                        $option.prop("selected",true);
                    }
                    $option.attr("disabled",!item[1]);
                    $input.append($option);
                }
            }
        }
    },

    updateTypeAheadOption: function($input, options) {
        var source = [];
        for(var i in options) {
            source.push({id:i,name:i});
        }

        $input.typeahead({
            source: source
        });
   },
    
    pager: function(page,limit,total_rows) {
        var items = 3;
        var body = "";
        
        if(total_rows > limit) {
            var pages = Math.ceil(total_rows/limit);
            
            body+= "<nav>";
            body+= "<ul class='pagination'>";
            
            for(var x=-items;x<=items;x++) {
                var pageNum = page+x;
                if(pageNum >= 0 && pageNum < pages) {
                    
                    body+= "<li class='page-item "+ (pageNum==page ? "active" : "")+"'>";
                    
                    if(x==-items && pageNum!=0) {
                        body+= "<a class='page-link' href='#' data-page='0'><i class='fa fa-angle-double-left'></i></a>";
                    } else if(x==items && pageNum!=pages-1) {
                        body+= "<a class='page-link' href='#' data-page='"+(pages-1)+"'><i class='fa fa-angle-double-right'></i></a>";
                    } else {
                        body+= "<a class='page-link' href='#' data-page='"+pageNum+"'>";
                        body+= (pageNum==page ? ""+(pageNum+1)+ " / "+ pages : (pageNum+1))+ " <span class='sr-only'>(current)</span></a>";
                    }
                    
                    body+= "</li>";
                }
            }
            
            body+= "</ul>";
            body+= "</nav>";
        }
        
        return body;
    }
};